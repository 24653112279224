<template>
  <product title="Amazfit 米动健康手环1S"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="699"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'health-band-1s',
  components: {
    Product
  },
  data () {
    return {
      slogan: 'AI 神经网络 24 小时心脏监测 | ECG 心电传感器 | 高精度光学传感器 <br/> 亲友健康关注',
      colors: [
        '黑色'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/health-band-1s/swiper/1.png',
          CDN_URL + '/images/product/health-band-1s/swiper/2.png',
          CDN_URL + '/images/product/health-band-1s/swiper/3.png',
          CDN_URL + '/images/product/health-band-1s/swiper/4.png'
        ]
      ],
      relativedProducts: [],
      detailImages: [
        CDN_URL + '/images/product/health-band-1s/detail/1.jpg',
        CDN_URL + '/images/product/health-band-1s/detail/2.jpg',
        CDN_URL + '/images/product/health-band-1s/detail/3.jpg',
        CDN_URL + '/images/product/health-band-1s/detail/4.jpg',
        CDN_URL + '/images/product/health-band-1s/detail/5.jpg',
        CDN_URL + '/images/product/health-band-1s/detail/6.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-band-1s/7.jpg',
        CDN_URL + '/images/product/health-band-1s/detail/8.jpg',
        CDN_URL + '/images/product/health-band-1s/detail/9.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/health-band-1s/detail/10.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
